import '../styles/App.css';
import Main from './Main.js'

function App() {
  return (
    <Main style={{ height: "100%", width: "100%", backgroundColor: "black" }} />
  );
}

export default App;
